<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs
            background-color="#002441"
            active-class="white black--text"
            height="60"
            dark
          >
            <v-tab>Per Order</v-tab>

            <!-- This Tab is for Entry -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <MerchantCommission />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <!-- Start Snackbar -->
    <v-snackbar v-model="snackbar" color="white" centered>
      <p class="black--text mb-0">{{ text }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
  </v-container>
</template>


<script>
  export default {
    data: () => ({
      snackbar: false,
      text: "",
      render: 0,
      transactionrender: 0,

      Order_Commission_array: [],
      transaction_to_accounts: [],
    }),

    components: {
      MerchantCommission: () =>
        import(
          /* Merchant Commission */ "@/views/merchantdashboard/components/commission/PerOrder.vue"
        ),
    },
  };
</script>


<style>
  .custom-header {
    background-color: #e5f1fb;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.19rem;
    height: 48px;
  }
</style>

